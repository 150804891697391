import Rx from 'rx';
import { List as ImmList, Set as ImmSet, Map as ImmMap, isCollection } from 'immutable';
import sha256 from 'crypto-js/sha256';

import BaseStore from '../BaseStore';
import Cache from '../Cache';
import { Store as SubscriberStore, ISubscriber } from './Subscriber';
import { ResourceType } from '../AppTypes';
import { escapeRegex } from '../utils/Misc';
import { isPhoneValid } from '../utils/Validation';
import { UserOrganizationProfile } from '../types/IUser';
import { check } from '../ACL';

let _searchTerms = ImmMap<string, number>();
let _cache = ImmMap<string, ImmSet<any>>();
const _resultsObservable = new Rx.ReplaySubject(1);
const _hotObservable = _resultsObservable.publish().refCount();

interface ISearchTermCacheValue {
    terms: {
        [key: string]: number;
    };
    timestamp: number;
}

async function getResource(resource: string): Promise<any> {
    switch (resource.toLocaleLowerCase()) {
        case 'activity':
            return await import('./Activity');
        case 'campaign':
            return await import('./Campaign');
        case 'list':
            return await import('./List');
        case 'contentblock':
            return await import('./ContentBlock');
        case 'subscriber':
            return await import('./Subscriber');
        case 'user':
            return await import('./User');
        default:
            return await import('./Null');
    }
}

export class Store extends BaseStore {
    public static resultObservable() {
        return _hotObservable;
    }

    public static getRecentSearchTerms(organization, limit = 10) {
        // {timestamp: xxxx, terms: {'testing': timestamp, ...}}
        return Cache.getItem(Cache.ns.search, 'terms', organization).flatMapLatest((data: ISearchTermCacheValue) => {
            if (!data || !data.terms) {
                return Rx.Observable.empty();
            }
            _searchTerms = ImmMap(data.terms);
            const terms = Object.keys(data.terms).sort((a: string, b: string) => data.terms[b] - data.terms[a]);
            return Rx.Observable.from([ImmList(terms).take(limit)]);
        });
    }

    public static async searchText(
        organization: UserOrganizationProfile,
        text: string,
        limit = 10,
        resource: ResourceType | null = null
    ) {
        if (!resource && _cache.has(text)) {
            const val = _cache.get(text) as ImmSet<any>;
            _resultsObservable.onNext(val);
            if (val.count() > limit) {
                return Rx.Observable.from([val]);
            }
        }

        const idStr = `~*${escapeRegex(text.trim())}`;
        const idNum = parseInt(text, 10);
        const queries: any[] = [];

        if (resource) {
            const resourceStore = await getResource(resource);
            if (idNum) {
                queries.push(resourceStore.Store.searchById(organization, idNum, limit).catch(Rx.Observable.empty()));
            }
            queries.push(resourceStore.Store.searchByName(organization, idStr, limit));
        } else {
            const activityStore = await getResource('activity');
            const campaignStore = await getResource('campaign');
            const listStore = await getResource('list');
            const contentBlockStore = await getResource('contentblock');
            const subscriberStore = await getResource('subscriber');
            const userStore = await getResource('user');

            queries.push(
                activityStore.Store.searchByName(organization, idStr, limit),
                campaignStore.Store.searchByName(organization, idStr, limit),
                listStore.Store.searchByName(organization, idStr, limit),
                contentBlockStore.Store.searchByName(organization, idStr, limit)
            );

            const hasDatabaseViewCapability = check(userStore.Store.getUserDetails(organization), organization, {
                capabilities: ImmSet(['database-view']),
            });

            if (idNum) {
                queries.push(
                    activityStore.Store.searchById(organization, idNum, limit).catch(Rx.Observable.empty()),
                    campaignStore.Store.searchById(organization, idNum, limit).catch(Rx.Observable.empty()),
                    listStore.Store.searchById(organization, idNum, limit).catch(Rx.Observable.empty()),
                    contentBlockStore.Store.searchById(organization, idNum, limit).catch(Rx.Observable.empty())
                );
            } else if (hasDatabaseViewCapability && text.indexOf('@') > 1) {
                queries.push(
                    subscriberStore.Store.searchByExpression(
                        organization,
                        `email like "${text.trim()}%" or email = "${sha256(text.trim().toLowerCase())}"`,
                        limit
                    )
                );
            }

            if (hasDatabaseViewCapability && isPhoneValid(text)) {
                const phoneQuery = `phone = "${text.trim()}"` || (idNum ? ` or id = ${idNum}` : '');
                queries.push(
                    subscriberStore.Store.searchByExpression(organization, phoneQuery, limit).flatMapLatest((data) => {
                        // If an exact phone match yields nothing, try to do a wildcard phone search
                        if (data.size > 0) {
                            return Rx.Observable.from([data]);
                        }
                        return subscriberStore.Store.searchByExpression(
                            organization,
                            `phone like "%${text.trim()}%"`,
                            limit
                        ).flatMapLatest((data) => {
                            // If wildcard search yields nothing, search by ID
                            if (data.size > 0) {
                                return Rx.Observable.from([data]);
                            }
                            return subscriberStore.Store.searchById(organization, idNum, limit).catch(
                                Rx.Observable.empty()
                            );
                        });
                    })
                );
            } else if (hasDatabaseViewCapability && idNum) {
                // Handle non-phone subscriber ID lookups separately to ensure the phone query doesn't supersede the ID lookup
                queries.push(subscriberStore.Store.searchById(organization, idNum, limit).catch(Rx.Observable.empty()));
            }
        }

        return Rx.Observable.combineLatest(queries, (...args) => {
            const results = args.reduce((acc, val) => (acc as ImmSet<any>).union(val as any), ImmSet()) as ImmSet<any>;
            return results.take(limit);
        })
            .catch((error: Error) => {
                _resultsObservable.onError(error);
                return Rx.Observable.empty();
            })
            .map((data) => this.update(text, data as ImmSet<any>))
            .do((result) =>
                window.requestIdleCallback(() =>
                    this.updateSearchTerms(organization, text, (result as ImmSet<any>).count())
                )
            );
    }

    public static searchTargetExpression(organization: UserOrganizationProfile, text: string, limit = 10) {
        return SubscriberStore.searchByExpression(organization, text, limit)
            .catch((error: Error) => {
                _resultsObservable.onError(error);
                return Rx.Observable.empty();
            })
            .map((data: ImmSet<ISubscriber>) => this.update(text, [data]))
            .do((result: ImmSet<ISubscriber>) =>
                window.requestIdleCallback(() => this.updateSearchTerms(organization, text, result.count()))
            );
    }

    public static async searchId(
        organization: UserOrganizationProfile,
        text: string,
        limit = 10,
        resource: ResourceType | null = null
    ) {
        let source;
        const idText = parseInt(text, 10);
        if (resource) {
            const resourceStore = await getResource(resource);
            source = resourceStore.Store.searchById(organization, idText, limit);
        } else {
            const idStr = `~*${escapeRegex(text.trim())}`;
            const activityStore = await getResource('activity');
            const campaignStore = await getResource('campaign');
            const listStore = await getResource('list');
            const contentBlockStore = await getResource('contentblock');
            const subscriberStore = await getResource('subscriber');
            source = Rx.Observable.zip(
                activityStore.Store.searchById(organization, idText, limit),
                campaignStore.Store.searchById(organization, idText, limit),
                listStore.Store.searchById(organization, idText, limit),
                contentBlockStore.Store.searchById(organization, idText, limit),
                subscriberStore.Store.searchById(organization, idText, limit).catch(Rx.Observable.empty()), // an error shouldn't affect the other results
                activityStore.Store.searchByName(organization, idStr, limit),
                campaignStore.Store.searchByName(organization, idStr, limit),
                listStore.Store.searchByName(organization, idStr, limit),
                contentBlockStore.Store.searchByName(organization, idStr, limit)
            );
        }

        return source
            .catch((error: Error) => {
                _resultsObservable.onError(error);
                return Rx.Observable.empty();
            })
            .map((data: Array<ImmSet<any>>) => this.update(text, data))
            .do((result: ImmSet<any>) =>
                window.requestIdleCallback(() => this.updateSearchTerms(organization, text, result.count()))
            );
    }

    public static async searchString(
        organization: UserOrganizationProfile,
        text: string,
        limit = 10,
        resource: ResourceType | null = null
    ) {
        const name = `~*${escapeRegex(text.trim())}`;
        let source;

        if (resource) {
            const resourceStore = await getResource(resource);
            source = resourceStore.Store.searchByName(name, limit);
        } else {
            const activityStore = await getResource('activity');
            const campaignStore = await getResource('campaign');
            const listStore = await getResource('list');
            const contentBlockStore = await getResource('contentblock');

            source = Rx.Observable.zip(
                activityStore.Store.searchByName(organization, name, limit),
                campaignStore.Store.searchByName(organization, name, limit),
                listStore.Store.searchByName(organization, name, limit),
                contentBlockStore.Store.searchByName(organization, name, limit)
            );
        }

        return source
            .catch((error: Error) => {
                _resultsObservable.onError(error);
                return Rx.Observable.empty();
            })
            .map((data: Array<ImmSet<any>>) => this.update(text, data))
            .do((result: ImmSet<any>) =>
                window.requestIdleCallback(() => this.updateSearchTerms(organization, text, result.count()))
            );
    }

    private static updateSearchTerms(organization: UserOrganizationProfile, text: string, resultSize: number) {
        if (!resultSize) {
            return;
        }

        // Don't add/update text if text is a substring of an existing term
        const subkeyExists = _searchTerms
            .keySeq()
            .filter((k: string) => k.indexOf(text) >= 0)
            .count();
        if (subkeyExists) {
            return;
        }

        // Update search terms only if there's a result set.
        // {timestamp: xxxx, terms: {'testing': timestamp, ...}}
        _searchTerms = _searchTerms.set(text, Date.now());
        Cache.getItem(Cache.ns.search, 'terms', organization.get('name')).subscribe((data: ISearchTermCacheValue) => {
            const existing = ImmMap(data && data.terms ? data.terms : Object.create(null));
            const val = {
                terms: existing.mergeDeep(_searchTerms).toJS(),
                timestamp: Date.now(),
            };
            Cache.setItem(Cache.ns.search, 'terms', val, organization.get('name')).subscribe();
        });
    }

    private static update(key: string, resultset: Array<ImmSet<any>> | ImmSet<any>) {
        let cacheVal: ImmSet<any> | undefined = ImmSet<any>();
        if (_cache.has(key)) {
            cacheVal = _cache.get(key);
        }

        if (typeof cacheVal === 'undefined') {
            _resultsObservable.onNext(ImmSet<any>());
            return cacheVal;
        }

        // Check if resultset is an Iterable.
        if (isCollection(resultset)) {
            resultset = [resultset] as Array<ImmSet<any>>;
        }

        // Return the cached values if there's nothing to update it with
        if (!resultset || !(resultset as Array<ImmSet<any>>).length) {
            _resultsObservable.onNext(cacheVal);
            return cacheVal;
        }

        cacheVal = cacheVal.clear();
        [].map.call(resultset, (result: any) => (cacheVal = cacheVal && cacheVal.union(result)));
        _cache = _cache.set(key, cacheVal);
        _resultsObservable.onNext(cacheVal);
        return cacheVal;
    }
}
