import * as Types from './AppTypes';

const sessionURI: string =
    process.env.TAGUCHI_SESSION_SRV === 'local'
        ? 'ws://localhost:8888/session'
        : 'wss://wss-next.login.taguchi.com.au/session';

const logoutURI: string =
    process.env.TAGUCHI_SESSION_SRV === 'local'
        ? 'ws://localhost:8888/logout?redirect=false'
        : 'https://wss-next.login.taguchi.com.au/logout?redirect=false';

const ssoRedirectURI: string =
    process.env.TAGUCHI_SESSION_SRV === 'local'
        ? 'ws://localhost:8888/sso_redirect'
        : 'https://wss-next.login.taguchi.com.au/sso_redirect';

const oidcTokenURI: string =
    process.env.TAGUCHI_SESSION_SRV === 'local'
        ? 'ws://localhost:8888/oidc_token'
        : 'https://wss-next.login.taguchi.com.au/oidc_token';
const sessionCookieURI: string =
    process.env.TAGUCHI_SESSION_SRV === 'local'
        ? 'http://localhost:8888/cookie'
        : 'https://wss-next.login.taguchi.com.au/cookie';

interface IConnectionConfig {
    readonly logoutUrl: string;
    readonly sessionUrl: string;
    readonly sessionCookieUrl: string;
    readonly ssoRedirectUrl: string;
    readonly oidcTokenUrl: string;
    readonly maxRetries: number;
    readonly notifyFailedRetries: number;
}

interface IAccountConfig {
    readonly otpIssuer: string;
}
interface IDefaultConfig {
    displayView: Types.ResourceItemViewType;
    filter: string;
    limit: number;
    assetLimit: number;
    credentialLimit: number;
    sortOrder: Types.SortOrder;
    sortKey: string;
    offset: number;
    readonly notificationLimit: number;
    readonly debounce: number;
    readonly throttleWindow: number;
    readonly cachedItemsDuration: number;
    readonly customFieldCachedDuration: number;
    readonly maxThrottle: number;
}

interface ISearchConfig {
    readonly dropdownLimit: number;
}

interface IStatsConfig {
    activityTileChartView: string;
    activityRowChartView: string;
    listTileChartView: string;
    readonly cacheDuration: number;
    readonly activityCacheDuration: number;
}

interface IAnimationConfig {
    readonly transitionDuration: number;
    readonly statsSmoothingDuration: number;
    readonly statsSmoothingInterval: number;
    readonly alertAutoFadeTimeout: number;
    readonly newWorkflowNotificationFadeTimeout: number;
}

interface IFlatPickrConfig {
    readonly enableTime: boolean;
    readonly time_24hr: boolean;
    readonly weekNumbers: boolean;
    readonly minDate?: Date;
    readonly altFormat: string;
    readonly altInput: boolean;
    readonly dateFormat: string;
}
interface IComponentConfig {
    readonly flatPickr: IFlatPickrConfig;
    readonly nanoid: number;
}

interface IRevisionContentConfig {
    schemaVersion: number;
}

interface IConfig {
    Account: IAccountConfig;
    Connection: IConnectionConfig;
    Defaults: IDefaultConfig;
    Search: ISearchConfig;
    Stats: IStatsConfig;
    Animations: IAnimationConfig;
    Components: IComponentConfig;
    RevisionContent: IRevisionContentConfig;
}

const AppConfig: IConfig = {
    Account: {
        otpIssuer: 'Taguchi',
    },
    Animations: {
        alertAutoFadeTimeout: 8000, // ms
        statsSmoothingDuration: 500, // ms
        statsSmoothingInterval: 500, // ms
        transitionDuration: 500, // ms
        newWorkflowNotificationFadeTimeout: 8000, // ms
    },
    Components: {
        flatPickr: {
            altFormat: 'H:i l, j F Y',
            altInput: true,
            dateFormat: 'Z', // ISO date format
            enableTime: true,
            time_24hr: true,
            weekNumbers: true,
        },
        // https://zelark.github.io/nano-id-cc/
        nanoid: 10,
    },
    Connection: {
        logoutUrl: logoutURI,
        maxRetries: 8, // ~ 2 minutes
        notifyFailedRetries: 3, // Notify user after N retries
        sessionUrl: sessionURI,
        sessionCookieUrl: sessionCookieURI,
        ssoRedirectUrl: ssoRedirectURI,
        oidcTokenUrl: oidcTokenURI, // OIDC token management route
    },
    Defaults: {
        cachedItemsDuration: 14 * 24 * 60 * 60 * 1000, // 14 days
        customFieldCachedDuration: 1000 * 60, // 1 min
        debounce: 400 /* ms */,
        displayView: 'grid',
        filter: 'all',
        limit: 25,
        assetLimit: 100,
        credentialLimit: 100,
        notificationLimit: 100,
        offset: 0,
        sortKey: 'id',
        sortOrder: 'desc',
        throttleWindow: 1000 /* ms */,
        maxThrottle: 60000,
    },
    RevisionContent: {
        schemaVersion: 3,
    },
    Search: {
        dropdownLimit: 10,
    },
    Stats: {
        activityCacheDuration: 1000 * 60 * 10, // 10 mins
        activityRowChartView: 'sent',
        activityTileChartView: 'sent',
        cacheDuration: 1000 * 60 * 60 * 24, // 24-hours
        listTileChartView: 'contactable',
    },
};

export default AppConfig;
