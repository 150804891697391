import { timeFormat } from 'd3-time-format';

const dateFormatter = timeFormat('%d %b %Y');

export const searchResultMapper = (val: any) => {
    // Description
    const desc = [`ID ${val.id}`];
    let resourceType = val.type ? val.type : val.__resourceType;
    if (val.__resourceType === 'activity') {
        const date = ' • ' + dateFormatter(new Date(val.date));
        const activityStatus =
            (resourceType === 'sms' || resourceType === 'email') &&
            (val.status === 'deployed' || val.status === 'scheduled') &&
            val.deploymentThrottle < 1
                ? 'suspended'
                : val.status;
        const archivedStatus = val.isArchived ? ' / archived' : '';
        desc.push(date + ' • ' + activityStatus + archivedStatus);
    } else if (val.__resourceType === 'campaign') {
        let date = val.startDate ? val.startDate : val.endDate;
        date = dateFormatter(new Date(date));
        const archivedStatus = val.isArchived ? ' • archived' : '';
        if (date) {
            desc.push(`\u00A0\u2022\u00A0${date}` + archivedStatus);
        } else if (val.isArchived) {
            desc.push(`\u00A0\u2022\u00A0 archived`);
        }
    } else if (val.__resourceType === 'subscriber') {
        // Display email if name is not an email address
        if (val.name.indexOf('@') === -1) {
            desc.push(`\u00A0\u2022\u00A0${val.email}\u00A0\u2022\u00A0subscriber`);
        } else {
            desc.push(`\u00A0\u2022\u00A0 subscriber`);
        }
    } else if (val.__resourceType === 'list' && val.isArchived) {
        desc.push(`\u00A0\u2022\u00A0archived`);
    } else if (val.__resourceType === 'contentBlock') {
        const assetStatus = val.isPublished ? ' • published' : ' • draft';
        const archivedStatus = val.isArchived ? ' / archived' : '';
        desc.push(assetStatus + archivedStatus);
    }

    // URL
    let url = '';
    let statusIcon =
        val.error && val.error.count
            ? 'error'
            : (resourceType === 'sms' || resourceType === 'email') &&
                val.status === 'approved' &&
                val.schedule &&
                !val.scheduleData.nextTimestamp
              ? 'cancelled'
              : (resourceType === 'sms' || resourceType === 'email') &&
                  (val.status === 'deployed' || val.status === 'scheduled') &&
                  val.deploymentThrottle < 1
                ? 'suspended'
                : val.status
                  ? val.status
                  : 'default';
    if (val.__resourceType === 'activity') {
        if (val.error && val.error.count) {
            url = `activity/viewerror/${val.id}`;
        } else {
            url = `activity/edit/${val.id}`;
        }
    } else if (val.__resourceType === 'campaign') {
        url = `campaign/${val.id}`;
    } else if (val.__resourceType === 'list') {
        url = `subscribers/report/${val.id}`;
    } else if (val.__resourceType === 'subscriber') {
        url = `subscribers/${val.id}`;
    } else if (val.__resourceType === 'contentBlock') {
        url = `assets/view/${val.id}`;
        resourceType = 'asset';
        statusIcon = val.isPublished ? 'published' : 'draft';
    }

    return {
        date: val.startDate || val.date,
        description: desc.join(''),
        iconType: `type-${
            (resourceType === 'sms' || resourceType === 'email') &&
            ((val.target && val.schedule) || (!val.target && val.status === 'deployed')) &&
            val.isArchived
                ? resourceType + '-triggered-archived'
                : (resourceType === 'sms' || resourceType === 'email') &&
                    ((val.target && val.schedule) || (!val.target && val.status === 'deployed'))
                  ? resourceType + '-triggered'
                  : val.isArchived
                    ? resourceType + '-archived'
                    : resourceType
        }`,
        id: val.id,
        isArchived: val.isArchived,
        key: `${resourceType}-${val.id}`,
        status:
            resourceType === 'asset' && val.isPublished
                ? 'published'
                : resourceType === 'asset' && !val.isPublished
                  ? 'draft'
                  : (resourceType === 'sms' || resourceType === 'email') &&
                      (val.status === 'deployed' || val.status === 'scheduled') &&
                      val.deploymentThrottle < 1
                    ? 'suspended'
                    : val.status
                      ? val.status
                      : 'default',
        statusIcon,
        title: val.name,
        type: resourceType,
        url,
    };
};
